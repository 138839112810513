<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="container" v-if="article">
      <div class="row" id="complete">
        <div class="col-12">
          <div class="row">
            <div class="col-12 title-art">
              <h1>{{ article.title }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12 px-0 px-md-2">
              <div class="article-text" v-html="article.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row" id="articles" v-if="!mobile && !load">
          <ArticlesCard :artInfo="posts" id="article" :numCards="items" />
        </div>
        <div class="row" v-else-if="!load">
          <div class="col-12" id="notiCarousel">
            <div class="row">
              <div class="col-12 title-art">
                <h1>{{locale[lang].ARTICLES_TITLE}}</h1>
              </div>
            </div>
            <div class="row">
              <NotiCarousel :mobile="mobile" :infoNoti="posts" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import ArticlesCard from "@/components/Home/ArticlesCards.vue";
import NotiCarousel from "@/components/NotiCarousel.vue";
import defaultMixin from "@/mixins/defaultMixin";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  mixins: [defaultMixin],
  components: {
    ArticlesCard,
    NotiCarousel,
    NavBar,
    Footer,
  },
  methods: {
   initMeta() {
      $("meta").remove();
      const metas = [
        {
          property: "db:app_id",
          content: "1282702448459969",
        },
        {
          property: "og:locale",
          content: "fr_FR",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: this.title,
        },
        {
          property: "og:description",
          lang: "fr-FR",
          content: this.description,
        },
        {
          property: "og:url",
          content: window.location.href,
        },
        {
          property: "og:site_name",
          content: "Préparation physique football",
        },
        {
          property: "og:image",
          content: this.coverImage,
        },
      ];
  
      for (const meta of metas) {
        const node = document.createElement("meta");
        for (const attr in meta) {
          node.setAttribute(attr, meta[attr]);
        }
        document.querySelector("head").appendChild(node);
      }
    },
  },
  watch: {
    article() {
      this.initMeta();
    }
  },
  computed: {
    items() {
      var value;
      if (this.arts.length < 3) {
        value = this.arts.length;
      } else {
        value = 3;
      }
      return value;
    },
    arts() {
      return this.$store.state.arts;
    },
    posts() {
      return this.$store.state.arts.filter(post => {
        return post.category.trim().replace(" ", "").toLowerCase().includes(this.article.category.trim().replace(" ", "").toLowerCase());
      });
    },
    article() {
      if (this.arts.length) {
        return this.arts.find(el => (el.slug == this.$route.params.id || (el.slug + '.php') == this.$route.params.id ) && el.year == this.$route.params.year);
      }
      return {};
    },
    title() {
      return this.article.title
    },
    description() {
      return this.article.description
    },
    coverImage() {
      return this.article.picture
    }
  },
  data() {
    return {
      load: true,
      category: null,
    };
  },
  created() {
    if (this.arts.length == 0) {
      this.$store.dispatch("GET_POSTS")
        .then(() => this.load = false)
        .catch(console.error);
    }
    this.load = false
  },
};
</script>

<style scoped>
#container {
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Blog/Enmascarar grupo 133.png"),
    url("../assets/Images/Blog/Enmascarar grupo 134.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Blog/Grupo 691.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 2% 44%, 100% 40%, 100% 65%, 0% 80%, 93% 95.8%;
  padding-bottom: 10%;
}

#nav-container {
  background-image: none;
  height: auto;
}

#nav-container >>> #logo {
  display: none;
}

.padding {
  padding: 0px 170px;
}

#blog {
  background-image: url("../assets/Images/Background/Grupo 129.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

#complete {
  padding: 5% 12%;
}

#articles {
  padding: 50px 5% 0;
}

#articles >>> .card {
  height: 94%;
}

.title-art h1 {
  text-align: center;
  font: normal normal 800 50px/61px Montserrat;
  letter-spacing: 0px;
  color: #545454;
  text-transform: uppercase;
  opacity: 1;
}

.article-text {
  padding: 5% 8% 0%;
}

.article-text >>> table {
  width: 100% !important;
  border-radius: 30px;
  border-collapse: initial;
}

.article-text >>> td {
  padding: 25px;
  border: none;
}

.article-text >>> .style-premier-par {
  font-style: italic;
}

.article-text >>> p,
.article-text >>> div,
.article-text >>> li {
  text-align: left;
  font: normal normal 500 20px/35px Montserrat;
  letter-spacing: 0.4px;
  color: #161616;
  opacity: 1;
}

.article-text >>> h3 {
  text-align: justify;
  font: normal normal 500 24px/35px Montserrat;
  letter-spacing: 0.4px;
  color: #8b8b8b;
  opacity: 1;
}

.article-text >>> a {
  text-align: left;
  text-decoration: underline;
  font: normal normal 500 20px/35px Montserrat;
  letter-spacing: 0.4px;
  color: #ff0000;
}

.article-text >>> .img-responsive {
  max-width: 100%;
}

.article-text >>> video {
  border-radius: 16px;
}

.article-text >>> iframe {
  border-radius: 16px;
  width: 100%;
  height: 620px;
  margin: 1rem 0;
}

.article-text >>> .embed-responsive {
  margin: 2rem 0;
}

.article-text >>> .fb_iframe_widget_fluid_desktop,
.article-text >>> .fb_iframe_widget_fluid_desktop span,
.article-text >>> .fb_iframe_widget_fluid_desktop iframe {
  max-width: 100%;
}

.article-text >>> .fb_iframe_widget {
  display: inline-block;
  position: relative;
}

.article-text >>> .fb_iframe_widget span {
  display: block;
  position: relative;
  text-align: justify;
}

.article-text >>> .fb-comments.fb_iframe_widget span {
  /* overflow-y: scroll; */
  min-height: 300px;
}

.article-text >>> .fb_iframe_widget iframe {
  min-width: 220px;
  position: relative;
  width: 100% !important;
  height: 130% !important;
}

.article-text >>> img {
  margin-bottom: 1rem;
  width: 100%;
  height: auto !important;
  border-radius: 15px;
}

.article-img,
.article-text >>> .article-img {
  margin-top: 50px;
  border-radius: 16px;
}

.article-text >>> .cell {
  border-radius: 16px;
  margin-top: 30px;
  border: 1px solid red;
  padding: 35px 30px 0;
}

.category,
.date {
  text-align: left;
  font: normal normal 500 15px/19px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

.date {
  color: #161616;
  padding: 15px 0;
}

.category {
  color: #ffffff;
  background: #2d2d2df2 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 15px 26px;
  margin-right: 80px;
  margin-bottom: 50px;
  text-transform: capitalize;
}

.row.buttons {
  margin-top: 80px;
}

.btn-category {
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  border-radius: 15px;
  padding: 14px 34px;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-category.active {
  background: #fc202e14 0% 0% no-repeat padding-box;
  color: #fc202e;
}

.form-group {
  margin-bottom: 0;
  margin-right: 40px;
}

.form-control {
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  background-color: transparent;
  padding: 19px 35px;
  height: auto;
  border: 1px solid #d6d6d6;
  border-radius: 15px;
}

.btn-left .fa-chevron-left,
.btn-right .fa-chevron-right {
  color: #c7c7c7;
  font-size: 25px;
}

.btn-left.red .fa-chevron-left,
.btn-right.red .fa-chevron-right {
  color: #ff0313;
}

@media (max-width: 1280px) {
  .article-text >>> iframe {
    height: 450px;
  }
}

@media (max-width: 825px) {
  #container {
    background-size: 50%;
  }
  #blog {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 217.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 110%;
    padding: 0 0 25% 0;
  }

  #articles {
    background-image: none;
  }

  #top {
    padding: 0;
  }

  #top >>> .img-fluid {
    height: auto;
  }

  #complete {
    padding: 5% 2%;
  }

  .category {
    margin: 5% 30%;
  }

  .title-art h1 {
    font-size: 22px;
    line-height: 24px;
  }

  .top {
    margin-top: 40px;
    display: block;
    margin-bottom: 40px;
  }

  .top p {
    text-align: center;
  }

  .article-text {
    padding: 10% 0%;
  }

  .article-text >>> p,
  .article-text >>> div,
  .article-text >>> a,
  .article-text >>> li {
    font-size: 17px;
    line-height: 20px;
  }

  .article-text >>> h3 {
    font-size: 20px;
    line-height: 25px;
  }

  .article-text >>> iframe {
    height: 400px;
  }

  .title-art {
    padding: 0 10%;
    margin-bottom: 40px;
    position: relative;
  }

  .title-art::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    bottom: -12px;
    left: 42.5%;
    opacity: 1;
  }

  .title-art h1 {
    text-align: center;
    font: normal normal 800 21px/25px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  #notiCarousel {
    padding: 0 15%;
  }

  #notiCarousel >>> .carousel-indicators li {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    opacity: 1;
  }

  #notiCarousel >>> .card-body {
    padding: 35px 18px 35px 26px;
  }

  #notiCarousel >>> .card-title {
    line-height: 19px;
    font-size: 16px;
  }

  #notiCarousel >>> .card-text {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  .buttons .row.justify-content-center {
    justify-content: space-evenly !important;
  }
  .group-0,
  .group-1,
  .group-2,
  .group-3 {
    margin-bottom: 15px;
  }

  .group-0 .btn-category {
    padding: 14px 50px;
  }

  .group-1 .btn-category {
    padding: 14px 22px;
  }

  .group-2 .btn-category {
    padding: 14px 10px;
  }

  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: center;
  }

  #top >>> #search-form {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  #notiCarousel {
    padding: 0;
  }

  #top >>> .title-sec h2,
  #top >>> .text-sec p {
    text-align: left;
  }

  #top >>> #search-form {
    display: block;
  }

  .article-text >>> iframe {
    height: 250px;
  }
}

@media (max-width: 375px) {
  .group-0 .btn-category {
    padding: 14px 40px;
  }

  .group-1 .btn-category {
    padding: 14px 10px;
  }

  .group-2 .btn-category {
    padding: 14px 5px;
  }

  .btn-left,
  .btn-right {
    padding: 0.375rem 5px;
  }
}
</style>
